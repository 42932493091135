.foodBanner {
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0, 0.5);
  background-image: url("https://hostindia.ca/wp-content/uploads/2021/02/Host-India-Takeout-Landing-Page.jpg");
  background-position: center center;
  background-size: cover;
  background-blend-mode: overlay;
  margin-bottom: 50px;
}

.foodBanner h1 {
  color: #fff;
  font-size: 2.4rem;
  max-width: 700px;
  text-align: center;
}

.menu-list {
  position: sticky;
  top: 0px;
  left: 0;
  background-color: #fff;
  z-index: 10;
  padding: 20px 0;
}

.menulisting-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menu-list h2 {
  position: relative;
  padding-bottom: 5px;
}

.menu-list h2::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 2rem;
  height: 1.5px;
  background-color: #000;
}

.menuItem {
  color: #000;
  font-size: 1.1rem;
}

.ant-tabs-tab-active .menuItem {
  color: var(--primeColor);
}

:where(.css-dev-only-do-not-override-3mqfnx).ant-tabs .ant-tabs-ink-bar {
  background-color: var(--primeColor);
}

.cart-icon-section {
  background-color: #13af4f;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.cart-icon-section svg {
  margin: 0 10px 0 0;
}

.headTitle {
  display: flex;
}

.headTitle img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
  margin: 0 20px 0 0;
}

.search-box {
  height: 50px;
  padding: 10px;
  border-radius: 50px;
  border: 2px solid #000;
  margin-right: 20px;
  display: flex;
  align-items: center;
  background-color: #eee;
}

.search-input {
  background-color: #eee;
  color: #000;
  outline: none;
  border: none;
  width: 0px;
  float: left;
  font-size: 14px;
  transition: 0.7s ease;
  font-family: sans-serif;
  padding: unset;
}

.search-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  background: #eee;
  border-radius: 50%;
  float: right;
  color: #000;
  transition: 0.4s ease;
  width: 28px;
  height: 28px;
  font-size: 1.2rem;
}

.search-box:hover > .search-input {
  width: 240px;
  margin: 0px 8px;
}

.search-input:focus {
  width: 240px;
  margin: 0px 8px;
}

.search-box:hover > .search-btn {
  background: #000;
  color: #fff;
}

.search-input:focus + .search-btn {
  background: #111;
  color: #fff;
}

.food-section {
  margin-top: 30px;
}

.food-section .container {
  border-bottom: 1px solid #afafaf;
}

.food-section:first-child {
  margin-top: 0px;
}

.food-section:last-child .container {
  border: unset;
}

@media (max-width: 768px) {
  .menulisting-head {
    display: flex;
  }

  .leftPanel {
    display: flex;
    justify-content: right;
    text-align: right;
    /* width: 100%; */
  }

  .maintext {
    display: flex;
  }
  .fontsizeone {
    font-size: 16px;
  }
  .headTitle img {
    width: 60px;
    height: 60px;
  }
  .menu-list h2 {
    font-size: 16px;
    padding: 0px;
  }
  .fontsizepera {
    font-size: 12px;
  }
  .headTitle {
    margin-bottom: 20px;
  }
}

@media (max-width: 430px) {
  .headTitle {
    display: flex;
  }
}
.flexitem {
  display: flex;
}

.foodList {
  margin-bottom: 30px;
}
.food-card-container {
  display: flex;
  justify-content: flex-start;
  /* align-items: center; */
  position: relative;
  padding: 10px 10px;
  border-radius: 7px;
  box-shadow: 0 0 3px #808080;
  margin: 20px 0 0px 0;
  height: 100%;
  max-height: 80%;
}
.food-card-image img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 10px;
}

.food-card-text {
  margin-left: 10px;
}

.food-card-text h3 {
  color: #111;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.food-card-text p {
  color: #111;
  font-size: 0.7rem;
  font-weight: 400;
  margin-bottom: 10px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.food-card-button h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: unset;
}

.food-card-button {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-start;
}

.add-to-cart-button-container {
  background-color: #12b04f;
  color: #fff;
  cursor: pointer;
  padding: 7px 15px;
  font-size: 0.9rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 5px;
  margin-left: 30px;
}

.add-to-cart-button-container svg {
  margin-left: 5px;
}

.okButton {
  background-color: transparent;
  border: 2px solid #12b04f;
  color: #12b04f;
  font-weight: 500;
  border-radius: 10px;
  transition: 0.4s ease-in-out;
}

.okButton:hover {
  background: #12b04f;
  color: #fff;
}
