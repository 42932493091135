.dropDesign {
  width: 100%;
  max-height: 500px;
  max-width: 700px;
  margin: auto;
  border: 2px dashed #cdcdcd;
  padding: 30px 20px;
  text-align: center;
  border-radius: 5px;
  margin: auto;
}

.dropDesign img {
  width: min(50vw, 100px);
  height: min(50vw, 100px);
  margin: auto;
}

.dropDesign h2 {
  font-size: min(1.6rem, 2.2rem);
}

.dropDesign p {
  color: #808080;
  max-width: 600px;
  text-align: center;
}

.foodListMenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.foodCardMenu {
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.foodCardMenu h5 {
  text-transform: capitalize;
  font-size: 1rem;
  margin: unset;
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #000;
}
.foodCardMenu h5 svg {
  color: #808080;
}

.vegNonvegSelect{
  display: flex;
}

.vegNonvegSelect .vegNonvegText{
  margin:  0 10px;
  display: flex;
  align-items: center;
}

.vegNonvegSelect .box{
  width: 15px;
  height: 15px;
  border: 1.5px solid currentColor;
  margin-right: 10px;
  position: relative;
}

.vegNonvegSelect .box::after{
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 10px;
  height: 10px;
  background-color: currentColor;
  border-radius: 50px;
}

.vegNonvegSelect .veg{
  color: green;
}

.vegNonvegSelect .nonVeg{
  color: red;
}

.viewDetails ul{
  padding: 0;
  margin: 0;
  list-style: none;

}

.viewDetails img{
  position: relative;
  width: 100%;
  max-height: 400px;
  border-radius: 5px;
  object-fit:fill;
}

.detail{
  position: relative;
}

.foodDetailContent{
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0px;
  width: 100%;
  right: 0;
  padding: 10px 20px;
  background: linear-gradient(90deg, rgb(0,0,0), rgb(0,0,0,.2));
  color: #fff;
}

.vegClass,
.nonVegClass{
  position: relative;
  width: 15px;
  height: 15px;
  border: 2px solid;
  margin-top: 5px;
}

.vegClass::after,
.nonVegClass::after{
  content: '';
  position: absolute;
  width: 8.5px;
  height: 8.5px;
  border-radius: 50px;
  background-color: currentColor;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.vegClass{
  color: green;
  border-color:green;
}

.nonVegClass{
  color: red;
  border-color:red;
}