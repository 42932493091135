.addButtons {
  height: 40px;
  font-size: 1rem;
  background-color: #000;
  color: #fff;
  transition: 0.5s ease-in-out;
  border: unset !important;
}

.addButtons:hover,
.addButtons:focus {
  color: #fff !important;
  transition: 0.5s ease-in-out;
}
.pageTitleDiv {
  padding: 15px 25px 0 25px;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
}
.pageTitle {
  color: #000;
  font-size: 2rem;
  text-transform: capitalize;
}

.cardDesign {
  box-shadow: 0 0 3px #808080;
  border-radius: 5px;
  padding: 10px;
}

.paginationButton {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 10px 25px;
}

.foodList {
  padding: 0 25px;
}

.StickyDrawer {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 0 3px #808080;
}

.contentDiv {
  position: relative;
  margin: "24px 16px";
}

.uploadButton {
  height: 40px;
  font-size: 1rem;
  background-color: green;
  color: #fff;
  transition: 0.5s ease-in-out;
  border: unset !important;
}

.cancelButton {
  height: 40px;
  font-size: 1rem;
  background-color: red;
  color: #fff;
  transition: 0.5s ease-in-out;
  border: unset !important;
}

.updateButton {
  height: 40px;
  font-size: 1rem;
  background-color: orange;
  color: #fff;
  transition: 0.5s ease-in-out;
  border: unset !important;
}

.updateButton:hover,
.updateButton:focus,
.cancelButton:hover,
.cancelButton:focus,
.uploadButton:hover,
.uploadButton:focus {
  color: #fff !important;
}

.formInput,
.formTextArea textarea {
  border: 1px solid #000 !important;
  border-radius: 5px !important;
  min-height: 40px;
  background-color: #f9f9f9 !important;
  transition: 0.5s ease-in-out;
  box-shadow: unset !important;
  outline: unset;
}

.formInput:hover,
.formInput:focus,
.formTextArea textarea:hover,
.formTextArea textarea:focus {
  box-shadow: unset !important;
  border: 1px solid #000 !important;
  background-color: #fff !important;
  transition: 0.5s ease-in-out;
  outline: unset !important;
}
.ant-select-selector {
  background: #f9f9f9 !important;
  border-color: #000 !important;
  box-shadow: unset !important;
  min-height: 40px;
  margin: unset;
  padding: unset;
  height: 40px;
  width: 100%;
}

.ant-select-selector:hover {
  background-color: #fff !important;
  border-color: #000;
}

.iconButton {
  border-color: #aaa !important;
  color: #aaa !important;
}

/* .cllapseItem{
  margin-bottom: 10px;
} */

.searchInput {
  position: relative;
}

.searchInput input {
  padding-left: 35px;
}
.searchInput .searchIcon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.cancelButtonWithIcon {
  background-color: transparent;
  color: red;
  border: unset;
  max-height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out;
}

.cancelButtonWithIcon svg {
  font-size: 0.8rem;
}

.cancelButtonWithIcon:focus,
.cancelButtonWithIcon:hover {
  box-shadow: unset;
  color: red !important;
  outline: unset;
  transition: 0.3s ease-in-out;
  transform: scale(1.2);
  border-color: red;
}

.menuCard {
  box-shadow: 0 0 2px #afafaf;
  width: 100%;
  height: 83vh;
  overflow-y: auto;
  border-radius: 5px;
}

.menuCard h4 {
  padding: 10px 10px;
  position: sticky;
  top: 0;
  background-color: #fff;
  left: 0;
  right: 0;
}

.menuCard ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.menuCard > ul {
  padding: 0 15px;
}
.cetegoryListCard {
  margin-bottom: 10px;
}

.cetegoryListCard h6 {
  margin: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 0 3px #afafaf;
  padding: 10px 10px;
  font-size: 0.9rem;
  color: #111;
  /* background-color: #eee; */
}

.cetegoryListCard h6 svg {
  cursor: grab;
}

.cetegoryListCard > ul {
  padding: 10px 30px 0 0;
}
/* 

.cetegoryListCard > ul > li > h6{
  background-color: #e3e3e3;
}


.cetegoryListCard > ul > li > ul > li > h6{
  background-color: #e9e9e9;
}

.cetegoryListCard > ul > li > ul > li > ul > li h6{
  background-color: #d8d8d8;
} */

.categorySearchedData {
  position: absolute;
  top: 45px;
  left: 10px;
  right: 10px;
  box-shadow: 2px 6px 15px #d2d2d2;
  padding: 10px;
  border-radius: 7px;
  z-index: 1;
  background-color: #fff;
}

.categorySearchedData ul {
  padding: 0;
  margin: 0;
}

.categorySearchedData ul li {
  padding: 5px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}

.categorySearchedData ul li:hover {
  background-color: #eee;
  cursor: pointer;
}

#subcategory {
  width: 80%;
  height: 25px;
  background-color: #eee;
  border-radius: 5px;
  margin-top: 10px;
  display: none;
}

.ComboCard {
  border: 1.5px solid transparent;
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

.ComboCard:hover {
  background-color: #eee;
  transition: 0.2s ease-in-out;
}
.ComboCard.active {
  background-color: #eee;
  border: 1.5px solid #000;
  transition: 0.2s ease-in-out;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-switch.ant-switch-checked:not(
    .ant-switch-disabled
  ) {
  background-color: green !important;
  color: green;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-switch:not(
    .ant-switch-disabled
  ) {
  background-color: red !important;
  color: red;
}

.dragCard {
  background-color: #fff;
  box-shadow: 0 0 2px #808080;
  margin: 10px 0;
  padding: 7px 10px !important;
  cursor: move;
}

.dragCard.dragging {
  opacity: 0.5;
}

.expandButton {
  margin: 7px;
  transition: 0.3s ease-in-out;
  width: 50px;
}

.expandButton span:last-child {
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in-out;
}
.expandButton:hover {
  width: 135px;
  transition: 0.3s ease-in-out;
}
.expandButton:hover span:last-child {
  opacity: 1;
  visibility: visible;
  transition: 0.3s ease-in-out;
}

.iconCard:hover {
  box-shadow: 0 0 3px #808080;
  background-color: #eee;
  transition: 0.3s ease-in-out;
  border-radius: 3px;
}

.iconCard.active {
  box-shadow: 0 0 0 2px #000;
  background-color: rgb(105, 220, 141, 0.4);
  border-radius: 3px;
}

/* ================= Action Buttons Icon ================ */

.iconButtonAction {
  background: transparent !important;
  border: unset !important;
  box-shadow: unset !important;
  font-size: 1rem;
}

.iconButtonAction.remove {
  color: red !important;
}

.iconButtonAction.edit {
  color: orange !important;
}

.iconButtonAction.success {
  color: green !important;
}

.iconButtonAction.blackButton {
  color: #000 !important;
}

.iconButtonAction:focus,
.iconButtonAction:hover {
  box-shadow: unset !important;
  outline: unset !important;
  border: unset !important;
}

/* ===================== Search Input ================= */

.searchInput input {
  background-color: #eee !important;
}
.searchInput {
  max-width: 400px;
  height: 40px;
  border-radius: 5px;
  background-color: #eee !important;
}

.searchInput:hover,
.searchInput:focus {
  border-color: #000;
  box-shadow: unset !important;
  outline: unset !important;
}

:where(.css-dev-only-do-not-override-gzal6t).ant-input-affix-wrapper:focus,
:where(
    .css-dev-only-do-not-override-gzal6t
  ).ant-input-affix-wrapper:focus-within {
  border-color: #000 !important;
}

/* ===================== Badge Color =================== */

.room {
  background: palevioletred;
  color: #fff;
}

.food {
  background: orange;
  color: #fff;
}

.roomCategory {
  background: green;
  color: #fff;
}

.foodCategory {
  background: blueviolet;
  color: #fff;
}

button:disabled {
  opacity: 0.5 !important;
}

.discountStatus {
  color: #fff;
}

.discountStatus.pending {
  background-color: orange;
}

.discountStatus.active {
  background-color: green;
}

.discountStatus.closed {
  background-color: red;
}

/* ========================= Detail View ====================== */

details > summary {
  list-style-type: none;
  position: relative;
}

details > summary::-webkit-details-marker {
  display: none;
}

details > summary::after {
  content: " ";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000;
  position: absolute;
  top: 15px;
  right: 10px;
  transition: 0.3s ease-in-out;
  transform: rotate(-90deg);
}

details[open] > summary::after {
  content: " ";
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #000;
  position: absolute;
  top: 15px;
  right: 10px;
  transition: 0.3s ease-in-out;
  transform: rotate(0deg);
}

details[open] > summary {
  transition: 0.3s ease-in-out;
  background-color: #eee;
  padding: 10px;
}

details summary {
  cursor: pointer;
  transition: margin 150ms ease-in-out;
  padding: 10px;
}

details[open] summary {
  margin-bottom: 10px;
  transition: 0.3s ease-in-out;
}

.priceList li {
  padding: unset !important;
}

p {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* ===================== Booking Table ================= */

.bookingDetail {
  width: 100%;
  overflow-x: scroll;
  max-width: 1300px;
  border-right: 0.5px solid #afafaf;
  border-left: 0.5px solid #afafaf;
  height: 100%;
  max-height: 480px;
  position: relative;
}
.bookingDetail > tr {
  box-shadow: 0 0 3px #afafaf;
}

.table_cell {
  width: 150px;
  min-width: 150px;
  height: 40px;
  text-align: center;
  border-right: 0.5px solid #afafaf;
}

.table_cell:last-child {
  border: unset;
}

.table_cell.sticky-cell {
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 1;
}

.table_detail .table_row > td {
  box-shadow: 0 0 1.5px #afafaf;
}

.table_detail .table_row {
  padding: 5px 0;
  display: block;
}

.table_head .table_cell {
  background-color: #f3f3f3;
}

.table_cell.sticky-cell h6 {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 150px;
}

.booking-cell:hover {
  background: #eee;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.table_head_body {
  position: relative;
}

.booking_data {
  height: 100%;
  position: relative;
  justify-content: flex-start;
  height: 100%;
  min-width: 200px;
  padding-left: 50px;
  padding-right: 50px;
  left: unset !important;
}

.booking_data:nth-child(even) {
  margin-top: 10px;
}

.booking_person_detail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #808080;
  color: #fff;
  width: 100%;
  padding: 5px 8px;
  border-radius: 5px;
}

.booking_data.booked .booking_person_detail {
  background-color: #00c3de;
}

.booking_data.check-in .booking_person_detail {
  background-color: #00d12f;
}

.booking_data.check-out .booking_person_detail {
  background-color: #ffa500;
}

.booking_person_detail svg {
  font-size: 14px;
  margin-right: 5px;
}

.booking_person_detail h6 {
  font-size: 14px;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.notFoundMessage {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 300px;
  margin: 50px auto;
}

.notFoundMessage img {
  width: 100%;
  height: 100%;
}

.css-9emuhu-MuiPaper-root-MuiDrawer-paper {
  max-width: 900px !important;
  margin: auto;
  min-height: 500px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.colorDetail
  :where(.css-dev-only-do-not-override-14fpsbw).ant-badge.ant-badge-status
  .ant-badge-status-dot {
  width: 15px !important;
  height: 15px !important;
}

/* =============== Check In Card ================= */

.checkInCard {
  box-shadow: 0 0 3px #808080;
  padding: 10px 20px;
  border-radius: 5px;
}

.checkInCardHeader strong {
  font-size: 1.1rem;
  color: orangered;
}

.checkInCardBody .detail {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 7px 0;
}
.checkInCardBody .detail h6 {
  font-size: 1rem;
  margin: 0;
  width: 100%;
}
.checkInCardBody .detail svg {
  font-size: 1.2rem;
  margin-right: 10px;
}

.checkInCardBody .extraFlex {
  display: flex;
}

/* ====================== Search Popup =============== */

.searchData {
  position: absolute;
  top: 100px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 5px #afafaf;
  padding: 0 10px 10px 10px;
  opacity: 0;
  transition: 0.3s ease-in-out;
  display: none;
  z-index: 1;
}

.searchData li {
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.searchData li:hover {
  transition: 0.2s ease-in-out;
  background-color: #ededed8f;
}

.searchData.active {
  top: 0px;
  opacity: 1;
  transition: 0.3s ease-in-out;
  display: block;
}

.profileCard {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 0 3px #808080;
  padding: 20px 20px;
  border-radius: 15px;
  transform: translateY(-100px);
  background: #fff;
}

.socialMedia ul {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  list-style: none;
  justify-content: center;
}

.socialMedia ul li {
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1.5px solid #111;
  margin: 0 10px;
  border-radius: 50px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.socialMedia ul li:hover {
  background-color: #000;
}

.socialMedia ul li svg {
  font-size: 1.2rem;
  color: #000;
}

.socialMedia ul li:hover svg {
  color: #fff;
}

.typeVegNonveg {
  display: block;
  width: 15px;
  height: 15px;
  min-width: 15px;
  border: 1.5px solid #000;
  position: relative;
}

.typeVegNonveg.Veg {
  border-color: green;
  color: green;
}

.typeVegNonveg.NonVeg {
  border-color: red;
  color: red;
}

.typeVegNonveg::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 10px;
  height: 10px;
  background-color: currentColor;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

/* ================= Forgote PAssword =============== */

.forgotePassword {
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputCard {
  width: 100%;
  max-width: 350px;
}

.inputCard p {
  color: #808080;
}

.imageDiv {
  max-width: 500px;
  margin: auto;
}

.imageDiv img {
  width: 100%;
  height: 100%;
}
.otp-input {
  text-align: center;
  width: 50px;
  height: 40px;
  margin-right: 10px;
}

.toggle-visibility {
  background-color: transparent;
  border: unset;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resend-button {
  background-color: transparent;
  border: unset;
  text-decoration: underline;
}

.inputCard a {
  color: #000;
}

.emptyClick {
  width: 100%;
  height: 100%;
}

.dataGet {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.badge.success {
  background: green;
}

.badge.pending {
  background: orange;
}

.badge.failed {
  background: red;
}

/* ================================ Dashboard =========================== */

.dashboardCard {
  background-color: #fff;
  box-shadow: 0px 0px 10px #eee;
  padding: 20px;
  border-radius: 10px;
}

.dashboardCard .rightPanel Button {
  color: #000;
  border-color: #000;
}

.dashboardCard .rightPanel Button:hover {
  color: #fff !important;
  border-color: #000 !important;
  background-color: #000;
}

.dashboardCard .leftPanel h5 {
  color: #000;
  font-weight: 600;
}

.dashboardCard .leftPanel p {
  color: #808080;
  font-size: 0.85rem;
}

.dashboardCounter {
  padding: 20px 20px 5px 20px;
  border-radius: 10px;
}

.dashboardCounter.red {
  background-color: rgba(255, 0, 0, 0.225);
}

.dashboardCounter.yellow {
  background-color: rgba(255, 166, 0, 0.387);
}

.dashboardCounter.purpule {
  background-color: rgba(128, 0, 128, 0.322);
}

.dashboardCounter.green {
  background-color: rgba(0, 128, 0, 0.262);
}

.dashboardCounter .icon {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  color: #fff;
}

.dashboardCounter.red .icon {
  background-color: rgba(255, 0, 0, 0.9);
}

.dashboardCounter.yellow .icon {
  background-color: orange;
}

.dashboardCounter.green .icon {
  background-color: green;
}

.dashboardCounter.purpule .icon {
  background-color: purple;
}

.dashboardCounter .count {
  font-size: 1.2rem;
  color: #000;
  font-weight: bolder;
}

.dashboardCounter .title {
  font-size: 0.9rem;
  color: #444;
}

.dashboardCounter p {
  font-size: 0.75rem;
  color: rgb(41, 41, 255);
}

.guestCard {
  box-shadow: 0px 0px 3px #afafaf;
  padding: 10px;
  border-radius: 5px;
}

.ant-modal-wrap,
.ant-modal-mask {
  z-index: 2000000 !important;
}

.addNewuttonGuest {
  border: 2px dashed #808080;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;
  opacity: 0.5;
}

input:disabled {
  /* opacity: 0.5 !important; */
  background-color: #afafaf;
  color: #808080 !important;
}

.socialIcon svg {
  font-size: 2rem;
}

.socialIcon.facebook svg {
  color: #1877f2;
}

.socialIcon.instagram svg {
  color: #cd486b;
}

.socialIcon.twitter svg {
  color: #000;
}

.socialIcon.youtube svg {
  color: #ff0000;
}

.socialIcon.whatsapp svg {
  color: #25d366;
}

.socialIcon.linkedin svg {
  color: #0072b1;
}

.socialIcon.threads svg {
  color: #000;
}

.paraGraph {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}


.brandLogo{
  position: fixed;
  max-width: 200px;
  bottom: 40px;
  right: 40px;
  z-index: 1000000;
  opacity: 0.5;
}

.admin_access{
  padding: 5px;
  width: 100%;
  background-color: #25d366;
  border-radius: 10px;
  margin-bottom: 10px;
}

.admin_access .container{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin_access button{
  background-color: transparent;
  border: unset;
  font-size: 1rem;
}

.admin_access button:hover{
  color: red !important;
}